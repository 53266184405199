export const ORDER_CREATE_UI = '801cc7cd-971d-4e6b-b681-3e476a7e3524';
export const ORG_ID = '7171bb41-185a-448f-aa40-c9ab64ab9ca2';
export const PAGE_SIZE = 10;
export const PAGE_NUMBER = 1;
export const DEFAULT_OPTION_COUNT = 50;
export const PAGE_SIZE_OPTIONS = [1, 5, 10, 15, 20];
export const WORKFLOW_INFO = {
  // todo: get this from backend
  IL: {
    name: 'International Logistics',
  },
  TP: {
    name: 'Transport',
  },
  OF: {
    name: 'Ocean Freight',
  },
  AF: {
    name: 'Air Freight',
  },
  CHB: {
    name: 'CHB',
  },
};
export const COUNTRY_FLAG_CDN_URL = (countryCode: string): string =>
  `https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/flags/1x1/${countryCode ? countryCode.toLowerCase() : ''}.svg`;
export const DEFAULT_DEBOUNCE_TIME = 300;
export const DEFAULT_VOLUME_WEIGHT_METRIC = 'Kg';
